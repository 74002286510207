import Vue from 'vue'

export default [
  {
    title: 'general.real_estates',
    icon: 'HomeIcon',
    children: [
      {
        title: 'general.real_estates_small',
        icon: 'HomeIcon',
        route: 'admin-real-estates-list'
      },
      {
        title: 'general.neighborhoods',
        icon: 'GitPullRequestIcon',
        route: 'neighborhoods-list'
      },
      {
        title: 'general.agents',
        icon: 'UsersIcon',
        route: 'real-estate-agents'
      }
    ],
    role: [Vue.prototype.$permissions.GetRealEstate]
  },
  {
    title: 'general.news',
    icon: 'FileIcon',
    route: 'admin-news-list',
    role: [Vue.prototype.$permissions.GetNews]
  },
  {
    title: 'general.services',
    icon: 'BookIcon',
    children: [
      {
        title: 'general.services_small',
        icon: 'BookIcon',
        route: 'admin-service-list'
      },
      {
        title: 'general.service_providers',
        icon: 'UsersIcon',
        route: 'service-providers'
      }
    ],
    role: [Vue.prototype.$permissions.GetService]
  },
  {
    title: 'general.ads',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'general.ads_small',
        icon: 'FileTextIcon',
        route: 'admin-classified-ad-list'
      },
      {
        title: 'general.categories',
        icon: 'FileTextIcon',
        route: 'admin-category-list'
      }
    ],
    role: [Vue.prototype.$permissions.GetClassifiedAd]
  },
  {
    title: 'general.users',
    icon: 'UserIcon',
    children: [
      {
        title: 'general.administrators',
        icon: 'UsersIcon',
        route: 'admin-list',
        role: [Vue.prototype.$permissions.GetAdminUsers]

      },
      {
        title: 'general.users_small',
        icon: 'UsersIcon',
        route: 'user-list',
        role: [Vue.prototype.$permissions.GetStaninvestUsers, Vue.prototype.$permissions.GetHabitUsers]
      },
      {
        title: 'general.roles',
        icon: 'UsersIcon',
        route: 'role-list',
        role: [Vue.prototype.$permissions.GetRoles]
      }
    ]
  },
  {
    title: 'general.shop',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'general.categories',
        icon: 'ShoppingBagIcon',
        route: 'webshop-category-list'
      },
      {
        title: 'general.promotions',
        icon: 'ShoppingBagIcon',
        route: 'webshop-promotions'
      },
      {
        title: 'general.installment_payment',
        icon: 'ShoppingBagIcon',
        route: 'webshop-installment-payment'
      },
      {
        title: 'general.webshop_configuration',
        icon: 'ShoppingBagIcon',
        route: 'webshop-configuration'
      }
    ],
    role: [Vue.prototype.$permissions.GetWebShop]
  },
  {
    title: 'general.bulletin_board',
    icon: 'ClipboardIcon',
    route: 'bulletin-board',
    role: [Vue.prototype.$permissions.GetHabitBulletin, Vue.prototype.$permissions.GetStaninvestBulletin]

  },
  {
    title: 'general.e_meetings',
    icon: 'VideoIcon',
    route: 'meetings',
    role: [Vue.prototype.$permissions.GetHabitMeetings, Vue.prototype.$permissions.GetStaninvestMeetings]

  },
  {
    title: 'general.collected_points',
    icon: 'GitPullRequestIcon',
    route: 'collected_points',
    role: [Vue.prototype.$permissions.GetCollectedPoints]
  },
  {
    title: 'general.faqs',
    icon: 'GitPullRequestIcon',
    route: 'faq-list',
    role: [Vue.prototype.$permissions.GetFAQ]
  },
  {
    title: 'general.text_edit',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'general.contacts',
        icon: 'GitPullRequestIcon',
        route: 'contacts'
      },
      {
        title: 'general.about_us',
        icon: 'GitPullRequestIcon',
        route: 'about-us'
      },
      {
        title: 'general.conditions',
        icon: 'GitPullRequestIcon',
        route: 'conditions'
      },
      {
        title: 'general.business_conditions',
        icon: 'GitPullRequestIcon',
        route: 'business-conditions'
      },
      {
        title: 'general.loyalty_points',
        icon: 'GitPullRequestIcon',
        route: 'loyalty-points'
      },
      {
        title: 'general.purchase_steps',
        icon: 'GitPullRequestIcon',
        route: 'purchase-steps'
      },
      {
        title: 'general.awarding',
        icon: 'AwardIcon',
        route: 'awarding'
      },
      {
        title: 'general.cookies',
        icon: 'GitPullRequestIcon',
        route: 'cookies'
      },
      {
        title: 'general.banner',
        icon: 'GitPullRequestIcon',
        route: 'banner'
      }
    ],
    role: [Vue.prototype.$permissions.GetPages]
  },
  {
    title: 'general.upravnik_vlozisca',
    icon: 'InboxIcon',
    children: [
      {
        title: 'general.staninvest',
        route: 'staninvest-upravnik-vlozisca',
        role: [Vue.prototype.$permissions.GetStaninvestVlozisce]
      },
      {
        title: 'general.habit',
        route: 'habit-upravnik-vlozisca',
        role: [Vue.prototype.$permissions.GetHabitVlozisce]
      }
    ]
  }
]
