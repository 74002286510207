<template>
    <div class="navbar-container d-flex content align-items-center">

        <b-navbar-nav class="d-none d-xl-flex nav container">
            <b-nav-item>
                <b-button variant="primary" @click="onGoToUserPortal" size="sm">
                    <feather-icon
                        size="16"
                        icon="ArrowRightCircleIcon"
                        class="mr-50"
                    />
                    <span>Nazaj na portal</span>
                </b-button>
            </b-nav-item>
        </b-navbar-nav>

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link
                    class="nav-link"
                    @click="toggleVerticalMenuActive"
                >
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                    />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <!--        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
            <dark-Toggler class="d-none d-lg-block" />
        </div>-->

        <b-navbar-nav class="nav container" style="justify-content: flex-end;">
            <b-nav-item-dropdown
                right
                toggle-class="d-flex align-items-center dropdown-user-link"
                class="dropdown-user"
            >
                <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p class="user-name font-weight-bolder mb-0">
                            {{ (`${userData.name} ${userData.surname}`) || userData.email }}
                        </p>
                        <span class="user-status">Admin</span>
                    </div>
                    <b-avatar
                        size="40"
                        variant="light-primary"
                        badge
                        :src="require('@/assets/images/avatars/13-small.png')"
                        class="badge-minimal"
                        badge-variant="success"
                    />
                </template>

                <b-dropdown-item class="d-xl-none" @click="onGoToUserPortal">
                    <feather-icon
                        size="16"
                        icon="ArrowRightCircleIcon"
                        class="mr-50"
                    />
                    <span>Nazaj na portal</span>
                </b-dropdown-item>

                <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
                    <feather-icon
                        size="16"
                        icon="LogOutIcon"
                        class="mr-50"
                    />
                    <span>Odjava</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>
</template>

<script>
    import {
        BNavbarNav, BNavItemDropdown, BDropdownItem, BNavItem, BLink, BButton, BAvatar
    } from 'bootstrap-vue'
    //import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
    import authService from '@/auth/authService'

    export default {
        components: {
            BNavbarNav,
            BNavItemDropdown,
            BDropdownItem,
            BNavItem,
            BLink,
            BButton,
            BAvatar

            // Navbar Components
            ///DarkToggler
        },
        props: {
            toggleVerticalMenuActive: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            return {
                userData: this.$store.state.user.userData
            }
        },
        methods: {
            logout() {
                authService.logOut()
                this.$router.push({name: 'login'})
            },
            onGoToUserPortal() {
                window.location.href = '/'
            }
        }
    }
</script>
